import {
  AfterViewInit,
  Component,
  ElementRef, EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  Pipe,
  PipeTransform,
  ViewChild
} from '@angular/core';
import {Observable, Subject, throwError} from "rxjs";
import {QuestionBase} from "../../../../create-adv/models/question-base";
import {FilterBase} from "../../../../../models/filter-base";
import {catchError, map} from "rxjs/operators";
import {SystemApp} from "../../../../../constans/SystemConfigurations";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {ApiUrl} from "../../../../../shared/models/apiurl.models";
import {LoadingMaskServiceService} from "../../../../../components/loading-frame/loading-frame.component";
import {FilterbaseService} from "../../filter-base/services/filterbase.service";
import {isArray} from "rxjs/internal-compatibility";


@Pipe({
  name: 'filterBasesFilter'
})
export class FilterBasesFilter implements PipeTransform {
  transform(data:any[], filterType: any, value:any): any {
    if (!data || !filterType) {
      return data;
    }
    return data.filter(item => item[filterType] == value);
  }
}


export interface SelectOption{
  id: string;
  label: string;
  name: string;
  value:{
    name: string;
    value: string;
  }
}

@Component({
  selector: 'app-select-field',
  templateUrl: './select-field.component.html',
  styleUrls: ['./select-field.component.css']
})
export class SelectFieldComponent implements OnInit, AfterViewInit {
  private options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.apiKey.getJWTToken()
    })
  }
  items: SelectOption[]=[];
  isOpened: boolean = false;
  selectedValue!: string;
  searchInput!: string;
  selectedObject!: any;
  isStaticField!: boolean;
  loadingData!: boolean;

  @Input() filterBase!: FilterBase;
  @Input() widthSize: number = 160;
  @Input() hideTitle: boolean =false;
  @Input() placeholder!: string;
  @Input() range!: boolean;
  @Output() outValue = new EventEmitter<string>();

  private subject = new Subject<any>();

  @ViewChild('dropdown') dropdown!: ElementRef;
  @ViewChild('dropdownSearchField') dropdownSearchField!: ElementRef;
  @ViewChild('searchInputElement') searchInputElement!: ElementRef;
  @ViewChild('clearButton') clearButton!: ElementRef;
  @Input() direction!: string;


  constructor(private http: HttpClient, private apiKey:ApiUrl, private loadingMaskServiceService: LoadingMaskServiceService, private filterBaseService: FilterbaseService) {
    this.filterBaseService.getRemoveEvent().subscribe((rs)=>{
      if(rs == this.filterBase.key){
        this.clearField();
      }
    })

    this.filterBaseService.getClickEvent().subscribe(res=>{
      const pathFetch = this.apiKey.pathTranslate(this.filterBase.callBackFunction, this.filterBaseService);
      if(pathFetch.requiredField.length>0){
        const relatedFields = pathFetch.requiredField.map(res => res.index);
        if(relatedFields.includes(res)){
          this.items = [];
          this.selectedObject = null;
          this.selectedValue = '';
          this.filterBaseService.deleteQuestion(this.filterBase.key)
        }
      }
    })
  }

  ngAfterViewInit(): void {

    // this.searchInputElement.nativeElement.offsetLeft = dropdownSearchFieldWidth;
  }

  @HostListener('window:click', ['$event'])
  onClick(btn:any) {
    if(btn.target.id ==='searchInput')
      return;
    this.isOpened = false;
  }
  @HostListener('keydown.enter', ['$event'])
  enterBtn(event: KeyboardEvent) {
    this.isOpened = false;
  }

  @HostListener('keydown', ['$event'])
  selectBtBtn(event: KeyboardEvent) {

    if(event.key === "ArrowDown"){
      const index = this.items.indexOf(this.selectedObject) == -1?0:this.items.indexOf(this.selectedObject);
      if(index < this.items.length-1) {
        this.selectItem(this.items[index + 1]);
      }

      const dropdownList = this.dropdown.nativeElement.getElementsByClassName("dropdown-list")[0];
      const element = this.dropdown.nativeElement.getElementsByClassName("selectedRow")[0];
      if(element != undefined){
        element.scrollIntoView(false);
      }
      dropdownList.scrollTop = (dropdownList.scrollTop+40);
    }
    if(event.key === "ArrowUp"){
        const index = this.items.indexOf(this.selectedObject)
        if(index > 0) {
          this.selectItem(this.items[index - 1]);
        }
        const dropdownList = this.dropdown.nativeElement.getElementsByClassName("dropdown-list")[0];
        const element = this.dropdown.nativeElement.getElementsByClassName("selectedRow")[0];
        if(element != undefined)
          element.scrollIntoView(false);
    }
    // this.scrollToSelectedRow();
  }
  scrollToSelectedRow(){
    const dropdownList = this.dropdown.nativeElement.getElementsByClassName("dropdown-list")[0];
    const element = this.dropdown.nativeElement.getElementsByClassName("selectedRow")[0];
    if(element != undefined)
      element.scrollIntoView(false);
      dropdownList.scrollTop = (dropdownList.scrollTop+40);
  }

  onClickFld(){

    // const dropdownSearchFieldWidth = this.dropdownSearchField.nativeElement.offsetWidth
    // this.searchInputElement.nativeElement.offsetLeft = dropdownSearchFieldWidth
    setTimeout(() => this.searchInputElement?.nativeElement?.focus(), 0);
    const pathFetch = this.apiKey.pathTranslate(this.filterBase.callBackFunction, this.filterBaseService, this.filterBase.additionalParam);
    if(isArray(this.items) && this.items.length==0){
      this.loadingData = true;
      this.fetchFilterData(pathFetch.path).subscribe(res=>{
        this.loadingData = false;
        this.items = res;
      })
    }

  }

  initStaticSelect(): void{
    let arr = this.filterBase.options.map( item =>{
        return {
          id: item.id,
          label: item.value,
          name: this.filterBase.key,
          value: {
            name: item.value,
            value: item.key
          }
        }
      }
    )
    this.items = arr;
  }

  private handleObserverError(error: any): Observable<any>{
    return throwError(error.error || error)
  }

  fetchFilterData(url: string): Observable<any>{
    return this.http.get(url, this.options)
      .pipe(map((res) => res ? res : null ),catchError(this.handleObserverError)
      );
  }

  initRemoteSelect():void{
    if(this.filterBase.callBackFunction === null || this.filterBase.callBackFunction === 'None')
      return;
    const callBackUrl =  SystemApp.base_url_api + "/" + this.filterBase.callBackFunction;
    const pathFetch = this.apiKey.pathTranslate(callBackUrl, this.filterBaseService);
    if(pathFetch.requiredField.length>0){

    }else {
      this.loadingData = true;
      this.fetchFilterData(callBackUrl).subscribe(res=>{
        this.items = res;
        this.loadingData = false;
      })
    }
  }

  ngOnInit(): void {
    if(this.filterBase.options.length>0){
      this.initStaticSelect();
    }else{
      this.initRemoteSelect();
    }
  }
  openMenu1():void{
    setTimeout(()=>{
      if(this.isOpened){
        this.isOpened = false;
      }else{
        this.isOpened = true;
      }
      setTimeout(()=> {
        const dropdownList = this.dropdown.nativeElement.getElementsByClassName("dropdown-list")[0];
        const element = this.dropdown.nativeElement.getElementsByClassName("selectedRow")[0];
        if (element != undefined) {
          element.scrollIntoView(false);
          dropdownList.scrollTop = (dropdownList.scrollTop+120);
        }
      },2)
      // dropdownList.scrollTop = (dropdownList.scrollTop+40);
    },1)
  }

  openMenu() :void{
    this.openMenu1();
    this.fieldSetting();
  }

  fieldSetting(): void{

  }

  selectItem(item:any, source:any=null) {
    this.selectedValue = item.label;
    this.selectedObject = item;
    if(this.range != undefined){
      this.onChangeValue(this.selectedObject);
    }else {
      this.filterBaseService.addQuestion(this.filterBase.key, this.selectedObject)
    }

    if(source!=null){
      this.isOpened = false;
    }
  }

  getClass(item: any) {
    if(this.selectedObject  === item){
      return 'selectedRow';
    }
    return ''
  }

  onBlur() {
    if(this.selectedValue == null || this.selectedValue == ""){
      this.selectedObject = null;
    }
    if(this.selectedObject != null){
      this.selectedValue = this.selectedObject.label;
    }else{
      this.selectedValue ='';
    }
  }

  getDropdownListStyle() {
    return {
      width:this.widthSize + 'px',
    }
  }
  getDynamicStyle() {
    return {
      width:this.widthSize + 'px',
    }
  }

  onChange($event:any) {
    if($event.key === 'ArrowLeft' || $event.key === 'ArrowRight' || $event.key === 'ArrowDown' || $event.key === 'ArrowUp'){
      return;
    }
    const pathFetch = this.apiKey.pathTranslate(this.filterBase.callBackFunction, this.filterBaseService);
    let url = pathFetch.path
    if($event != null){
      const query = this.searchInput
      if(query != null){
        url = url + "?q="+query
      }
    }
    this.fetchFilterData(url).subscribe(res=>{
      this.loadingData = false;
      this.items = res;
    })
  }

  searchInputClick() {

  }

  onChangeValue(delta: any) {
    this.outValue.emit(delta);
  }
  clearEventHandler(){
    this.filterBaseService.sendRemoveEvent(this.filterBase.key);
  }
  clearField() {
    this.filterBaseService.deleteQuestion(this.filterBase.key);
    this.selectedObject = null;
    this.selectedValue = '';
  }
}
