export class StringUtils {
    /**
     * Checks if a string is null, undefined, or empty.
     */
    static isEmpty(str: string | null | undefined): boolean {
        return !str || str.trim().length === 0;
    }

    /**
     * Capitalizes the first letter of a string.
     */
    static capitalize(str: string): string {
        if (this.isEmpty(str)) return str;
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    /**
     * Truncates a string to a specified length and adds an ellipsis if truncated.
     */
    static truncate(str: string, maxLength: number): string {
        if (str.length <= maxLength) return str;
        return str.slice(0, maxLength) + '...';
    }

    /**
     * Removes leading and trailing whitespace from a string.
     */
    static trim(str: string): string {
        return str.trim();
    }

    /**
     * Checks if a string contains only numeric characters.
     */
    static isNumeric(str: string): boolean {
        return /^\d+$/.test(str);
    }

    /**
     * Reverses a string.
     */
    static reverse(str: string): string {
        return str.split('').reverse().join('');
    }

    /**
     * Counts the occurrences of a substring within a string.
     */
    static countOccurrences(str: string, subStr: string): number {
        return (str.match(new RegExp(subStr, 'g')) || []).length;
    }
}
