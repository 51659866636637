import {Injectable} from "@angular/core";
import {Observable, Subject} from "rxjs";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {ApiUrl} from "../../../../../shared/models/apiurl.models";
import {LoadingMaskServiceService} from "../../../../../components/loading-frame/loading-frame.component";
import {FilterBase} from "../../../../../models/filter-base";
import {StringUtils} from "../../../../../utils/StringUtils";


@Injectable()
export class FilterbaseService {
  private subject = new Subject<any>();
  private removeSubject = new Subject<any>();
  private options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.apiKey.getJWTToken()
    })
  }
  filterBases: FilterBase[] = [];
  filterBaseMap = new Map();

  limit=36;
  offset=1;

  constructor(private http: HttpClient, private apiKey: ApiUrl, private loadingMaskServiceService: LoadingMaskServiceService) {

  }

  clearRelatedFields(questionKey: string,source: string|null =null ): void{
    const arrList = this.filterBases.find(qs=>qs.key == questionKey)?.relatedField.split(",");
    if(arrList ==null || arrList!.length == 0 ) return;
    arrList.forEach((field:any)=>{
      if(StringUtils.isEmpty(field) ){
        this.filterBaseMap.delete(field);
      }
    })
    if(source == "clearSearch"){
      this.deleteQuestion(questionKey)
    }

  }
  addQuestion(index: string,answer: any){
    this.deleteQuestion(index);
    this.clearRelatedFields(index);
    this.filterBaseMap.set(index,answer)
    this.sendClickEvent(index)
  }

  deleteQuestion(index: string, direction: string=null){
    this.clearRelatedFields(index);
    this.filterBaseMap.delete(index);
    this.sendClickEvent(index)
  }

  getQuestion(index: string) {
    return this.filterBaseMap.get(index);
  }

  loadFilterData(limit: number, offset: number):Observable<any>{
    var arrJson:any = {}
    const mainCategory = this.apiKey.getNameSpacePos('/',3);
    const subCategory = this.apiKey.getNameSpacePos('/',5);
    arrJson['mainCategory'] = mainCategory;
    arrJson['subCategory'] = subCategory;
    this.filterBaseMap.forEach((value, key) => arrJson[key]=value)
    // return this.http.get<any>(this.apiKey.getFilterBaseUrl(arrJson), this.options)
    return this.http.post(this.apiKey.getFilterBasePostUrl(mainCategory, limit, offset), this.apiKey.buildPayload(mainCategory, subCategory, arrJson), this.options);
  }

  sendClickEvent(fieldChange: string){
    this.subject.next(fieldChange);
  }

  getClickEvent():Observable<any>{
    return this.subject.asObservable();
  }


  sendRemoveEvent(fieldChange: any){
    this.removeSubject.next(fieldChange);
  }

  getRemoveEvent():Observable<any>{
    return this.removeSubject.asObservable();
  }

}
