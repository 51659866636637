import {Component, OnInit, Pipe, PipeTransform} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {ApiUrl} from "../../shared/models/apiurl.models";
import {LoadingMaskServiceService} from "../../components/loading-frame/loading-frame.component";
import {Observable, throwError} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {Product} from "../../models/product";
import {SystemApp} from "../../constans/SystemConfigurations";
import {TranslateService} from "@ngx-translate/core";
import {DatePipe} from "@angular/common";
import {AppService } from '../../../app/app.service';
declare function singlePage(): any;
export enum EntityType{
  NADLAN="Nadlan",
  CAR="Car",
  SALE2="Sale2"
}
export interface DetailsRow{
  field: string,
  fieldType: string,
  itemType: string | null,
  indexItemName: string | null,
  headerName?: string
}

@Pipe({ name: 'getValueItemProduct' })
export class GetValueItemProduct implements PipeTransform{
  constructor(private  translateService: TranslateService, private datePipe: DatePipe) {

  }
  transform(value: any, ...args: any[]): any {
    const product = args[0]
    const headerName =value.headerName? this.translateService.instant(value.headerName): value.field
    if(value.fieldType == 'date'){
      const date = new Date(product[value.field]).toLocaleDateString();
      return headerName + ': '+ this.datePipe.transform(date,"dd/MM/yyyy hh:mm a")
    }
    if(value.fieldType == "list"){
      return headerName + ': ' + product[value.field][0][value.indexItemName]
    }
    if(value.fieldType == "boolean"){
      return headerName + ': ' +  (product[value.field] ? this.translateService.instant("sale4you.label.true"): this.translateService.instant("sale4you.label.false"))
    }
    if(value.fieldType == "object"){
      return headerName + ': ' + product[value.field][value.indexItemName]
    }
    return headerName + ': ' + product[value.field]
  }
}

@Component({
  selector: 'app-single',
  templateUrl: './single.component.html',
  styleUrls: ['./single.component.css']
})
export class SingleComponent implements OnInit {
  private options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.apiKey.getJWTToken()
    })
  }


  productObj!: Product;
  base_url: string;
  tableDetailsList: DetailsRow[]=[];


  constructor(private http: HttpClient, private apiKey: ApiUrl, private loadingMaskServiceService: LoadingMaskServiceService,public translate:TranslateService,public appService:AppService) {
    this.base_url = SystemApp.base_url_api;
    const productId = this.apiKey.getNameSpacePos("/", 3);
    this.loadData(productId)
  }
  getDetailsByType(objectType:EntityType| null):DetailsRow[]{
    switch ( objectType ) {
      case EntityType.CAR:
        return this.carFieldFunc();
        break;
      case EntityType.NADLAN:
        return this.nadlanFieldFunc();
        break;
      case EntityType.SALE2:
        return this.sale2FieldFunc();
        break;
    }
    return [];
  }
  carFieldFunc(): DetailsRow[] {
    return [
      {field: 'CodeProducer', fieldType: 'null', itemType: 'object', indexItemName: 'producer', headerName: "sale4you.label.CodeProducer"},
      {field: 'MainModel', fieldType: 'null', itemType: null, indexItemName: 'model' , headerName:"sale4you.label.MainModel"},
      {field: 'sconderyCategory', fieldType: 'object', itemType: null, indexItemName: 'name', headerName:"sale4you.label.sconderyCategory"},
      {field: 'mainCategory', fieldType: 'object', itemType: null, indexItemName: 'name', headerName:"sale4you.label.mainCategory"},
      {field: 'Color', fieldType: 'object', itemType: null, indexItemName: 'colorName', headerName:"sale4you.label.Color"},
      {field: 'area', fieldType: 'null', itemType: null, indexItemName: 'area', headerName:"sale4you.label.area"},
      {field: 'activeDescription', fieldType: 'null', itemType: null, indexItemName: 'active', headerName:"sale4you.label.active"},
      {field: 'year', fieldType: 'null', itemType: null, indexItemName: 'year', headerName:"sale4you.label.year"},
      {field: 'DrivingTest', fieldType: 'null', itemType: null, indexItemName: 'DrivingTest', headerName:"sale4you.label.DrivingTest"},
      {field: 'YearTest', fieldType: 'null', itemType: null, indexItemName: 'YearTest', headerName:"sale4you.label.YearTest"},
      {field: 'MonthTest', fieldType: 'null', itemType: null, indexItemName: 'MonthTest', headerName:"sale4you.label.MonthTest"},
      {field: 'mileage', fieldType: 'null', itemType: null, indexItemName: 'mileage', headerName:"sale4you.label.mileage"},
      {field: 'engineCapacity', fieldType: 'null', itemType: null, indexItemName: 'engineCapacity', headerName:"sale4you.label.engineCapacity"},
      {field: 'gearBox', fieldType: 'null', itemType: null, indexItemName: 'gearBox', headerName:"sale4you.label.gearBox"},
      {field: 'licenseNumber', fieldType: 'null', itemType: null, indexItemName: 'licenseNumber', headerName:"sale4you.label.licenseNumber"},
      {field: 'DisabledSuitable', fieldType: 'boolean', itemType: null, indexItemName: 'DisabledSuitable', headerName:"sale4you.label.DisabledSuitable"},
      {field: 'exchange', fieldType: 'null', itemType: null, indexItemName: 'exchange', headerName:"sale4you.label.exchange"},
      {field: 'monthOnRoad', fieldType: 'null', itemType: null, indexItemName: 'monthOnRoad', headerName:"sale4you.label.monthOnRoad"},
      {field: 'CurrentOwner', fieldType: 'object', itemType: null, indexItemName: 'Type', headerName:"sale4you.label.CurrentOwner"},
      {field: 'PastOwner', fieldType: 'object', itemType: null, indexItemName: 'Type', headerName:"sale4you.label.PastOwner"},
      {field: 'NumberOfOwner', fieldType: 'object', itemType: null, indexItemName: 'numberOnwer', headerName:"sale4you.label.numberOnwer"},
    ]
  }

  nadlanFieldFunc(): DetailsRow[] {
    return [
      {field: 'mainCategory', fieldType: 'object', itemType: null, indexItemName: 'name', headerName:"sale4you.label.mainCategory"},
      {field: 'sconderyCategory', fieldType: 'object', itemType: null, indexItemName: 'name', headerName:"sale4you.label.sconderyCategory"},
      {field: 'TypeOfNadlan', fieldType: 'object', itemType: null, indexItemName: 'nameHe', headerName:"sale4you.label.TypeOfNadlan"},
      {field: 'City', fieldType: 'object', itemType: null, indexItemName: 'nameHe', headerName:"sale4you.label.City"},
      {field: 'Street', fieldType: 'object', itemType: null, indexItemName: 'nameHe', headerName:"sale4you.label.Street"},
      {field: 'RoomsNum', fieldType: 'object', itemType: null, indexItemName: 'numberOfRooms', headerName:"sale4you.label.RoomsNum"},
      {field: 'ParkingNumber', fieldType: 'object', itemType: null, indexItemName: 'numberParking', headerName:"sale4you.label.ParkingNumber"},
      {field: 'Balcony', fieldType: 'null', itemType: null, indexItemName: 'Balcony', headerName:"sale4you.label.Balcony"},
      {field: 'updateTime', fieldType: 'null', itemType: null, indexItemName: 'updateTime', headerName:"sale4you.label.updateTime"},
      {field: 'HomeNum', fieldType: 'null', itemType: null, indexItemName: 'HomeNum', headerName:"sale4you.label.HomeNum"},
      {field: 'Floor', fieldType: 'null', itemType: null, indexItemName: 'Floor', headerName:"sale4you.label.Floor"},
      {field: 'TotalFloor', fieldType: 'null', itemType: null, indexItemName: 'TotalFloor', headerName:"sale4you.label.TotalFloor"},
      {field: 'Neighborhood', fieldType: 'null', itemType: null, indexItemName: 'Neighborhood', headerName:"sale4you.label.Neighborhood"},
      {field: 'Area', fieldType: 'null', itemType: null, indexItemName: 'Area', headerName:"sale4you.label.area"},
      {field: 'Entrance', fieldType: 'null', itemType: null, indexItemName: 'Entrance', headerName:"sale4you.label.Entrance"},
      {field: 'StatusOfNadlan', fieldType: 'null', itemType: null, indexItemName: 'StatusOfNadlan', headerName:"sale4you.label.availability"},
    ]
  }

  sale2FieldFunc(): DetailsRow[] {
    return [
      {field: 'typeProduct', fieldType: 'null', itemType: null, indexItemName: 'typeProduct', headerName:"sale4you.label.typeProduct"},
      {field: 'thirdCategory', fieldType: 'null', itemType: null, indexItemName: 'thirdCategory', headerName:"sale4you.label.thirdCategory"},
      {field: 'area', fieldType: 'null', itemType: null, indexItemName: 'area', headerName:"sale4you.label.area"},
      {field: 'mainCategory', fieldType: 'null', itemType: null, indexItemName: 'mainCategory', headerName:"sale4you.label.mainCategory"},
      {field: 'sconderyCategory', fieldType: 'null', itemType: null, indexItemName: 'sconderyCategory', headerName:"sale4you.label.sconderyCategory"},
      {field: 'HomeNum', fieldType: 'null', itemType: null, indexItemName: 'HomeNum', headerName:"HomeNum"},
      {field: 'Floor', fieldType: 'null', itemType: null, indexItemName: 'Floor', headerName:"Floor"},
    ]
  }

  ngOnInit(): void {

  }
  loadData(productId: string): void{
    this.loadingMaskServiceService.setOnLoadingMask(true, true);
    this.getProduct(productId).subscribe((res)=>{
      this.productObj = res;

      this.fixData(this.productObj)

      this.tableDetailsList = this.getDetailsByType(this.productObj.resourcetype);
      setTimeout(()=>singlePage(),200)
      this.loadingMaskServiceService.setOnLoadingMask(false, false);
    },()=>{
      this.loadingMaskServiceService.setOnLoadingMask(false, false);
    })
  }

  private handleObserverError(error: any): Observable<any>{
    this.loadingMaskServiceService.setOnLoadingMask(false, false);
    return throwError(error.error || error)
  }

  getProduct(productId:string): Observable<any>{
    return this.http.get(this.apiKey.getProduct(productId), this.options)
      .pipe(map((res) => res ? res : null ),catchError(this.handleObserverError)
      );
  }


  getProperty(item: DetailsRow) {
    if(item.field == "images")
      return;

    return "abed"
  }
  fixData(productObj:Product ){
    if( productObj.resourcetype==EntityType.CAR){
      if(this.productObj.activeDescription){
        this.productObj.activeDescription=this.translate.instant('sale4you.label.status.active')
      }
      this.productObj.activeDescription=this.translate.instant('sale4you.label.status.inactive')

      if(this.productObj.DrivingTest){
        this.productObj.DrivingTest=this.translate.instant('sale4you.label.HaveDrivingTest')
      }
      this.productObj.DrivingTest=this.translate.instant('sale4you.label.HaveNotDrivingTest')

      if(this.productObj.exchange){
        this.productObj.exchange=this.translate.instant('sale4you.label.exchange')
      }
      this.productObj.exchange=this.translate.instant('sale4you.label.Notexchange')


    }
    if(productObj.resourcetype==EntityType.NADLAN){
      if(this.productObj.Balcony){
        this.productObj.Balcony=this.translate.instant('sale4you.label.have')
      }
      this.productObj.Balcony=this.translate.instant('sale4you.label.haveNot')

      if(this.productObj.Entrance){
        this.productObj.Entrance=this.translate.instant('sale4you.label.have')
      }
      this.productObj.Entrance=this.translate.instant('sale4you.label.haveNot')
    }

    if(productObj.resourcetype==EntityType.SALE2){

    }

  }
  public addToWishList($event:any,product: Product){
    $event.preventDefault();
    this.appService.addToWishList(product);
  }

}
