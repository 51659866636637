import {Component, Input, OnInit} from '@angular/core';
import {AdvFormService} from "../../pages/create-adv/services/adv-form.service";
import {ApiUrl} from "../../shared/models/apiurl.models";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {FormSubCategory} from "../../models/forms.model";
import {Observable, throwError} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {QuestionPriority} from "../../constans/formsQuestions";
import {QuestionBase} from "../../pages/create-adv/models/question-base";
import {SystemApp} from "../../constans/SystemConfigurations";
import {selectOptionData} from "../../pages/create-adv/dynamic-form-question/dynamic-form-question.component";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-select-related-description',
  templateUrl: './select-related-description.component.html',
  styleUrls: ['./select-related-description.component.css']
})
export class SelectRelatedDescriptionComponent implements OnInit {

  private options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.apiKey.getJWTToken()
    })
  }

  @Input()
  questionBase!: QuestionBase<string>;
  @Input()
  questionKey!: any;
  selectedData!: null;
  descriptionList: any [] = [];
  availableCars: selectOptionData[];
  @Input()
  myProp: any;
  base_url: String = SystemApp.base_url_api;

  constructor(private advFormService: AdvFormService,private apiKey: ApiUrl, private http: HttpClient, private translate: TranslateService) {
    this.advFormService.getClickEvent().subscribe((res: any)=>{
      setTimeout(()=>{
        if(res != this.questionKey) return;
        this.descriptionList = []
        this.selectedData = null
      },50)
    })
    this.advFormService.getClickEvent().subscribe(res=>{
      const codeProducer = this.advFormService.getQuestion('producerDescription');
      const mainModel = this.advFormService.getQuestion('mainModelDescription');
      const year = this.advFormService.getQuestion('year');
      if(codeProducer != undefined && mainModel != undefined && year != undefined){
        this.descriptionList = []
        this.getDescription(codeProducer.value.value, mainModel.value.value, year.value.value).subscribe((res:selectOptionData[])=>{
          this.descriptionList = []
          this.availableCars = res
          const descriptionStr = this.getDescriptionCar(res);
          this.descriptionList.push(...descriptionStr);
        });

      }

    })
  }

  getDescriptionCar(carDetails: selectOptionData[]){
    return carDetails.map((carObj:any)=>{
      return {
      "label": this.translate.instant('sale4you.label.licenseCodeDes.header.2', carObj['label']),
      "name": "description",
      "value": {
        "value": this.translate.instant('sale4you.label.licenseCodeDes.header.2', carObj['label']),
        "name": "description"
      }
    }})
  }

  highlightSelectedData(category: selectOptionData): string{
    const index = this.questionKey;
    if(this.advFormService.getQuestion(index) == undefined)
      return '';
    if(this.advFormService.getQuestion(index).label == category.label)
      return 'selectBigBoxSelected';
    return '';
  }

  private handleObserverError(error: any): Observable<any>{
    return throwError(error.error || error)
  }

  getDescription(producer: string, mainModel: string, year: string): Observable<any>{
    return this.http.get(this.apiKey.getCarDescription(producer, mainModel, year), this.options)
      .pipe(map((res) => res ? res : null ),catchError(this.handleObserverError)
      );
  }


  ngOnInit(): void {
    if(this.questionBase != null){}
  }

  SelectMainCategory(category: selectOptionData, selectedIndex: number) {
    const index = this.questionKey;
    const selectedOpt:selectOptionData = this.availableCars[selectedIndex];
    this.advFormService.addQuestion("engineCapacity", selectedOpt.label['engineCapacity'])
    this.advFormService.addQuestion(index,category);

  }

}
